import { defineStore } from "pinia"
import { getConfig,listConfig,tripartiteAPI,specialcarAPI } from "@/api/Home.js";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
  const useUserStore = defineStore("user", {
    state: () => ({
        locationarr: ["山东省", "济宁市"],
      logisticsList:[],
      totalRecord:"",
      markedwords:"",
      specialList:[],
      txtst:"",
      zxtotal:"",
      tripartiteList:[],
      sftotal:"",
      specialcarList:[],
      zyctotal:"",
      majorList:[],
      djtotal:"",
      wxpList:[],
      wxptotal:"",
      tcpList:[],
      tcptotal:"",
      yqccList:[],
      yqcctotal:"",
     gjhdList:[],
     gjhdtotal:"",
 
    }),
    actions: {
      
      // 当前位置
      locationarrfn(val) {
        this.locationarr = val;
        console.log(val);
        
      },
      sstxtfn(val) {
        // console.log(val);
        this.txtst = val;
      },
    listfn(val){
      return new Promise((resolve, reject) => {
        getConfig(val).then((res) => {
          this.logisticsList= res.data.list;
          this.totalRecord= res.data.totalRecord;
          if (res.data.totalRecord) {
            this.markedwords=""
          }else{
            this.markedwords="暂无数据"
          }
          resolve();
        });
      
      });
    
    },
    specList(){
      return new Promise((resolve, reject) => {
        listConfig({
          pageNum: "1",
          pageSize: "10",
          startLocation: this.locationarr.join("/"),
          endLocation: " ",
        }).then((res) => {
          console.log(res.data.list);
          this.specialList = res.data.list;
          this.zxtotal = res.data.totalRecord;
          // resolve();
        });
        tripartiteAPI({
          companyCategory: 1,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.tripartiteList = res.data.list;
          this.sftotal = res.data.totalRecord;
        });
        tripartiteAPI({
          companyCategory: 2,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.specialcarList = res.data.list;
          this.zyctotal = res.data.totalRecord;
        });
        tripartiteAPI({
          companyCategory: 3,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.majorList = res.data.list;
          this.djctotal = res.data.totalRecord;
        });
        tripartiteAPI({
          companyCategory: 4,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.wxpList = res.data.list;
          this.wxptotal = res.data.totalRecord;
        });
        tripartiteAPI({
          companyCategory: 5,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.tcpList = res.data.list;
          this.tcptotal = res.data.totalRecord;
        });
        tripartiteAPI({
          companyCategory: 6,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.yqccList = res.data.list;
          this.yqcctotal = res.data.totalRecord;
        });
        tripartiteAPI({
          companyCategory: 7,
pageNum: 1,
pageSize: 10,
province: this.locationarr[0],
city:" "

        }).then((res) => {
          this.gjdhList = res.data.list;
          this.gjdhtotal = res.data.totalRecord;
        });
      });
     
    },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
          this.token = "";
          this.roles = [];
          this.permissions = [];
         
        });
      },
    },
  });
  
  export default useUserStore;
  